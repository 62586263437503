<template>
    <div class="_wrap">
        <div class="_list" v-for="(item, index) in contentList" :key="index" @click="goDetail(item)">
            <div class="_left">
                <div class="_time">
                    <h4>{{ item.release_date.substring(8, 10) }}</h4>
                    <p>{{ item.release_date.substring(0, 7) }}</p>
                </div>
                <div class="_r_l">
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.introduction }}</p>
                </div>


            </div>
            <div class="_right">

                <div class="_r_r">
                    <div class="_img">
                        <!-- <img src="@/assets/image/information-img/jt.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { hits } from '@/api/getData'
export default {
    props: {
        contentList: {
            type: Array
        }
    },
    methods: {
        goDetail(item) {
            if (item.link != null && item.link != '') {
                const params = {
                    type: 1,
                    id: item.id
                }
                hits(params)
                window.open(item.link)
            } else {
                let url = location.origin
                window.open(`${url}/informationDetail?id=${item.id}&breadName=通知公告`)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../common/mixin.scss";

._list
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;

    ._left
    {

        display: flex;
        align-items: center;
        width: 90%;

        ._time
        {
            text-align: center;
            margin-right: 90px;

            h4
            {
                color: #B41E1E;
                font-size: 48px;
            }

            p
            {
                color: #666;
                letter-spacing: 1px;
                font-size: 18px;
            }
        }

        ._r_l:hover h4
        {
            color: $hover-color;
        }


        ._r_l
        {
            width: 85%;

            h4
            {
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 22px;
                letter-spacing: 1px;
                color: #333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }

            p
            {
                font-size: 20px;
                letter-spacing: 1px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #999;
            }
        }

    }

    ._left:has(._r_l:hover)+._right ._img
    {
        background-image: url("../../../assets/image/information-img/jt1.png");
    }

    ._right
    {

        ._r_r
        {
            ._img
            {
                background-image: url("../../../assets/image/information-img/jt.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 30px;
                height: 30px;
            }

        }



    }
}


@include respondTo('phone'){

   
    ._list{
        padding: 10px 0;
        ._left{

            // width: 80%;
            ._time{
                margin-right: 20px;
                width: 78px;
                h4{
                    font-size: 22px;
                }
                p{
                    font-size: 16px;
                }
            }

            ._r_l{

                width: 67%;
                h4{
                    font-size: 20px;
                }
                p{
                    font-size: 16px;
                }
            }
        }
        ._right{

            flex: 1;
        }
    }
}
</style>