<template>
    <div class="_build">
        <div class="_topList" v-if="buildTop.length > 0">
            <div class="_left" @click="goDetail(buildTop[0])">
                <div class="_leftImg">
                    <img :src="$IMG_URL + buildTop[0].cover" alt="八路军驻洛办事处纪念馆-官方网站">
                </div>

                <div class="_js">
                    <h4>{{ buildTop[0].title }}</h4>
                    <p>{{ buildTop[0].introduction }}
                    </p>
                </div>
            </div>

            <div class="_right">
                <div class="_list" v-for="(item, index) in buildThree" :key="index" @click="goDetail(item)">
                    <h4 class="_impact">{{ item.release_date }}</h4>
                    <h5>{{ item.title }}</h5>
                    <p>{{ item.introduction }}</p>
                </div>
            </div>

        </div>


        <div class="_bottomList" v-if="contentList.length > 0">
            <div class="_li" v-for="(item, index) in contentList" :key="index" @click="goDetail(item)">
                <div class="_liImg">
                    <img :src="$IMG_URL + item.cover" alt="八路军驻洛办事处纪念馆-官方网站">
                    <div class="_time">
                        <h4 class="_impact">{{ item.release_date.substring(8, 10) }}</h4>
                        <p class="_impact">{{ item.release_date.substring(0, 7) }}</p>
                    </div>
                </div>

                <div class="_nr">
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.introduction }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { hits } from '@/api/getData'

export default {
    props: {
        contentList: {
            type: Array
        },
        buildTop: {
            type: Array
        },
        buildThree: {
            type: Array
        }
    },
    methods: {
        goDetail(item) {
            if (item.link != null && item.link != '') {
                const params = {
                    type: 1,
                    id: item.id
                }
                hits(params)
                window.open(item.link)
            } else {
                let url = location.origin
                window.open(`${url}/informationDetail?id=${item.id}&breadName=党建工作`)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../common/mixin.scss";

._build
{
    ._topList
    {
        display: flex;

        ._left
        {
            width: 65%;
            height: 510px;
            position: relative;
            cursor: pointer;

            ._leftImg
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;

                img
                {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .8s;

                }

                img:hover
                {
                    transform: scale(1.1);
                }
            }

            ._js
            { 
                width: 92%;
                position: absolute;
                bottom: 50px;
                left: 41px;
                color: white;

                h4
                {
                    width: 100%;
                    font-size: 24px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 20px;
                    letter-spacing: 1px;
                }

                p
                {
                    line-height: 25px;
                    letter-spacing: 1px;
                }
            }
        }

        ._right
        {
            width: 40%;
            background-color: #fff;
            height: 510px;
            overflow: hidden;


            ._list
            {
                // color: white;
                padding: 24px 57px 46px 30px;
                border-bottom: 1px solid #F8F7F7;
                cursor: pointer;
                overflow: hidden;
                transition: all .3s;

                h4
                {
                    font-size: 18px;
                    color: #999;
                    // margin-bottom: 15px;
                    letter-spacing: 1px;
                }
                h5{
                    font-size: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 5px 0;
                    font-weight: bold;
                }
                p
                {
                    color: #333;
                    letter-spacing: 1px;
                    font-size: 16px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                }
            }

            ._list:hover
            {
                transform: scale(1.1);
                background-color: #B41E1E;
            }

            ._list:hover h4
            {
                color: white;
            }
            ._list:hover h5
            {
                color: white;
            }

            ._list:hover p
            {
                color: white;
            }
        }
    }


    ._bottomList
    {
        margin: 60px 0;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;

        ._li:hover ._nr h4
        {
            color: $hover-color;
        }

        ._li
        {
            width: 31.3%;
            margin: 30px 0;
            margin-right: 3%;

            cursor: pointer;

            ._liImg
            {
                width: 100%;
                height: 303px;
                position: relative;
                overflow: hidden;

                img
                {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    transition: all .8s;
                }

                img:hover
                {
                    transform: scale(1.1);
                }

                ._time
                {
                    color: white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 84px;
                    height: 84px;
                    background: rgba(0, 0, 0, 0.5);
                    text-align: center;


                    h4
                    {
                        font-size: 40px;
                        margin-top: 5px;
                    }

                    p
                    {
                        font-size: 18px;
                    }
                }
            }

            ._nr
            {
                h4
                {
                    color: #333;
                    font-size: 20px;
                    margin: 25px 0 15px;
                    letter-spacing: 1px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                }

                p
                {
                    color: #666666;
                    width: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    letter-spacing: 1px;


                }
            }

            &:nth-child(3n)
            {
                margin-right: 0;
            }
        }

        ._li:hover ._nr h4
        {
            color: #B41E1E;
        }
    }
}

@include respondTo('phone'){

    ._build{

        ._topList{

            display: block;
            ._left{
                width: 100%;
                height: 280px;

                ._js{
                    left: 10px;
                    bottom: 10px;
                    width: 100%;
                    h4{
                        margin-bottom: 0;
                    }
                }

            }
            ._right{
                width: 100%;
                height: auto;
                ._list{
                    padding: 10px;
                }
            }
        }
        ._bottomList{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;
            ._li{
                width: 48%;
                margin: 0;
               margin-bottom: 10px;

               ._liImg{
                height: 120px;

                ._time{
                    width: 80px;
                    height: 60px;
                    h4{
                        font-size: 22px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
               }
               ._nr{
                h4{
                    font-size: 18px;
                    margin: 0;
                    margin-top: 10px;
                }
                p{
                    display: none;
                }
               }
                
            }
        }

    }
}
</style>