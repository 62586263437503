<template>
  <div class="_wrap">
    <div class="_banner">
      <img :src="$IMG_URL + $store.state.webData.top_image.information_list_image" alt="八路军驻洛办事处纪念馆-官方网站">
    </div>

    <div class="_tabs" id="Jump">
      <div class="_auto">
        <div class="_tit">资讯</div>
        <div class="_list">
          <div :class="{ _li: true, _active: current == index }" v-for="(item, index) in tabList" :key="index"
            @click="changeTab(item, index)">{{ item.name }}</div>
        </div>
      </div>

    </div>

    <div class="_auto">
      <div class="_bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>资讯</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadTit }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>


      <div class="_allList">
        <!-- 通知公告 -->
        <transition>
          <div v-if="current == 1">
            <noticeList ref="noticeListRef" :contentList="contentList"></noticeList>
          </div>
        </transition>


        <!-- 新闻资讯 -->
        <transition>
          <div v-if="current == 0">
            <newsList ref="newsListRef" :contentList="contentList"></newsList>
          </div>
        </transition>

        <!-- 党建 -->
        <transition>

          <div v-if="current == 2">

            <buildList ref="buildListRef" :contentList="contentList" :buildTop="buildTop" :buildThree="buildThree">
            </buildList>
          </div>
        </transition>

      </div>



      <div class="_page">
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
          @pagination="getList" />

      </div>

    </div>




    <mainFooter ref="mainFooterRef"></mainFooter>

  </div>
</template>

<script>
import noticeList from './model/noticeList'
import newsList from './model/newsList.vue'
import buildList from './model/buildList.vue'
import { informationList } from '../../api/getData'
export default {
  components: {
    noticeList,
    newsList,
    buildList
  },
  data() {
    return {
      title: "八路军驻洛办事处纪念馆-资讯",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      breadTit: "通知公告",
      total: 100,
      queryParams: {
        page: 1,
        page_num: 10,
        cate_id: 2,
      },
      current: 0,
      tabList: [
        {
          name: "新闻资讯",
          cate_id: 2,
        },
        {
          name: "通知公告",
          cate_id: 1,
        },
        {
          name: "党建工作",
          cate_id: 3,
        }
      ],
      contentList: [],
      buildTop: [],
      buildThree: [],
    }
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {

    return {
      title: this.title,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    }
  },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      let title = to.query.tit
      this.breadTit = title
      if (title) {
        this.$scrollToSection("Jump")

        if (title == '通知公告') {
          this.current = 1
          this.queryParams.cate_id = 1
          this.queryParams.page_num = 10

        } else if (title == '新闻资讯') {
          this.current = 0
          this.queryParams.cate_id = 2
          this.queryParams.page_num = 10


        } else {
          this.current = 2
          this.queryParams.cate_id = 3
          this.queryParams.page_num = 6



        }

      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动
        })
      }
      this.getList()
    }
  },


  mounted() {
    let title = this.$route.query.tit
    if (title) {
      this.$scrollToSection("Jump")
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      })
    }
  },
  created() {
    let title = this.$route.query.tit
    if (title) {
      this.breadTit = title
      if (title == '通知公告') {
        this.current = 1
        this.queryParams.cate_id = 1
        this.queryParams.page_num = 10
      } else if (title == '新闻资讯') {
        this.current = 0
        this.queryParams.cate_id = 2
        this.queryParams.page_num = 10

      } else {
        this.current = 2

        this.queryParams.cate_id = 3
        this.queryParams.page_num = 6


      }
    }
    this.getList()
  },
  methods: {
    changeTab(item, index) {

      this.current = index
      this.breadTit = item.name
      this.queryParams.page = 1
      this.queryParams.cate_id = item.cate_id
      if (item.name == '党建工作') {
        this.queryParams.page_num = 6

      } else {
        this.queryParams.page_num = 10

      }
      this.getList()
    },
    getList() {
      informationList(this.queryParams).then(res => { 
        this.total = res.data.total

        this.contentList = res.data.data

        if (this.queryParams.cate_id == 3) {

          let topData = res.top
          this.buildTop = topData

          let arr = []
          for (let i in this.buildTop) {
            if (i > 0) {
              arr.push(this.buildTop[i])
            }
          }
          this.buildThree = arr
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._wrap
{
  width: 100%;
  overflow: hidden;
  background-color: #FDFAF6;
}

._banner
{
  width: 100%;
  height: 700px;
  object-fit: cover;

  img
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}

._allList
{
  margin-top: 50px;
}

._auto
{
  width: 75%;
  margin: 0 auto;
}

._tabs
{
  width: 100%;
  background-color: #fff;

  // padding: 0px 0;
  ._auto
  {
    display: flex;

  }

  ._tit
  {
    margin-right: 40px;
    color: white;
    // background-color: #B41E1E;
    background-image: url("../../assets/image/information-img/com_bg_title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // padding: 20px 40px;
    width: 183px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    font-size: 30px;
  }

  ._list
  {
    display: flex;
    align-items: center;

    ._li
    {
      margin: 0 20px;
      color: #333;
      font-weight: bold;
      font-size: 24px;
      padding: 25px 0;
      cursor: pointer;
    }

    ._active
    {
      color: #B41E1E;
      border-bottom: 4px solid #B41E1E;

    }

    // ._active::after{
    //   content: "";
    //   display: inline-block;
    //   width: ;
    // }
  }
}

._bread
{
  margin: 40px 0 20px;
}

::v-deep .el-breadcrumb__inner
{
  color: #969696 !important;
}


._page
{
  text-align: center;
  margin-bottom: 40px;
}


.v-enter-active,
.v-leave-active
{
  transition: all .5s;
}

.v-enter,
.v-leave-to
{
  transform: translateX(100px);
  opacity: 0;
}

// .v-enter{
//   transform: translateX(-1000);
//   opacity: 0;
// }
// .v-leave-to{
//   transform: translateX(100px);
//   opacity: 0;
// }



@include respondTo('phone'){
  ._auto{
    padding: 0 8px;
  }

  ._page{
    margin-bottom: 20px;
    margin-top: 10px;
  }

  ._banner{
    height: 300px;

  }
  ._bread{
    margin: 20px 0;
  }
  ._allList{
    margin-top: 0;
  }
  ._tabs{

    ._auto{
      display: block;
      ._tit{
        width: 140px;
        height: 60px;
        line-height: 60px;
        margin-right: 0;

      }
      ._list{
        justify-content: space-between;
        ._li{
          font-size: 22px;
          margin: 0;
          padding: 15px 0;
        }
      }
    }
  }
}

</style>